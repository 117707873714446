<template>
    <cluji-card title="Paramétrage des permanences"
                subtitle="Permanences récurrentes qui seront ajoutées au calendrier automatiquement">
        <b-row class="mb-3">
            <b-col lg="3" cols="6"> Nom</b-col>
            <b-col lg="3" cols="6"> Jour</b-col>
            <b-col lg="2" cols="6"> Heure de début</b-col>
            <b-col lg="2" cols="6"> Heure de fin</b-col>
        </b-row>
        <b-form @submit.prevent="submit">
            <b-form-group v-for="(permanence, index) in permanences" :key="'permanence_' + permanence.id">
                <b-row>
                    <b-col lg="3" cols="6">
                        <b-input type="text" v-model="permanence.nom" placeholder="Permanence"/>
                    </b-col>
                    <b-col lg="3" cols="6">
                        <multi-select v-model="permanence.jour"
                                      :options="daysOfWeek"
                                      :multiple="false"
                                      :close-on-select="true"
                                      track-by="value"
                                      :clear-on-select="true"
                                      :preserve-search="false"
                                      label="text"/>
                    </b-col>
                    <b-col lg="2" cols="6">
                        <b-input type="time" v-model="permanence.heureDebut"/>
                    </b-col>
                    <b-col lg="2" cols="6">
                        <b-input type="time" v-model="permanence.heureFin"/>
                    </b-col>
                    <b-col lg="2" cols="6" :style="{'line-height': '43px'}">
                        <b-button @click="deleteRow(permanence.id)" variant="danger" size="sm">
                            <font-awesome-icon icon="minus"/>
                        </b-button>
                        <b-button v-if="index === permanences.length - 1" @click="addRow" variant="success" size="sm">
                            <font-awesome-icon icon="plus"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form-group>
            <div class="text-center mt-3">
                <b-button variant="success" type="submit">
                    <font-awesome-icon icon="save"/>
                    Enregistrer
                </b-button>
            </div>
        </b-form>
    </cluji-card>
</template>

<script>
    import {apiPath} from '@/util/http';
    import alert     from '@/util/alert';

    const ClujiCard   = () => import('@/components/ClujiCard');
    const MultiSelect = () => import('@/components/MultiSelect');

    export default {
        name: "AdminPermanencesSettings",
        components: {ClujiCard, MultiSelect},
        data: () => ({
            newLineNumber: 0,
            daysOfWeek: [
                {value: 1, text: 'Lundi'},
                {value: 2, text: 'Mardi'},
                {value: 3, text: 'Mercredi'},
                {value: 4, text: 'Jeudi'},
                {value: 5, text: 'Vendredi'},
                {value: 6, text: 'Samedi'},
                {value: 7, text: 'Dimanche'}
            ],
            permanences: []
        }),
        methods: {
            submit() {
                alert.loading();
                this.axios
                    .post(
                        apiPath('admin_save_permanences'),
                        {
                            permanences: this.permanences
                                .filter(p => p.jour && p.jour.value && p.heureDebut && p.heureFin)
                                .map(permanence => ({
                                    ...permanence,
                                    jour: permanence.jour.value
                                }))
                        }
                    )
                    .then(() => this.$toaster.success('Modifications enregistrées'))
                    .catch(() => this.$toaster.error("Impossible d'enregistrer les permanences"))
                    .finally(alert.stopLoading)
                    .then(() => this.loadData());
            },
            addRow() {
                this.permanences.push({
                    id: --this.newLineNumber,
                    jour: null,
                    heureDebut: null,
                    heureFin: null
                })
            },
            deleteRow(rowId) {
                this.permanences = this.permanences.filter(({id}) => id !== rowId);
            },
            loadData() {
                alert.loading();
                return this.axios.get(apiPath('admin_list_permanences'))
                    .then(response => this.permanences = response.data.map(permanence => ({
                        ...permanence,
                        jour: this.daysOfWeek.find(({value}) => value === permanence.jour)
                    })))
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des permanences'))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData().then(() => {
                if (!this.permanences.length) {
                    this.addRow();
                }
            });
        }
    }
</script>
